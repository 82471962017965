<template>
	<form @submit.prevent="checkForm" class="mb-4">
		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>
		</div>
		
		<div class="row">
			<div class="col-12">
				<div v-show="modelable_options.length > 1" class="form-group">
					<label for="modeltype_label">{{ $t('model.relation') }} *</label>
					<e-select
						v-model="modelable_type"
						track-by="id"
						label="name"
						:options="modelable_options"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						preselect-first
						:class="{ 'is-invalid': errors && errors.indexOf('modeltype_label') > -1 }"
					>
					</e-select>
				</div>

				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="modeltype"
						id="modeltype_id"
						track-by="name"
						label="name"
						:placeholder="labelTitle"
						:selectedLabel="selectedLabel"
						:options="model_types"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoading"
						:show-labels="false"
						:class="{ 'is-invalid': errors && errors.indexOf('modeltype_label') > -1 }"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
					<label for="model_label">{{ $t('model.model_label') }} *</label>
					<input type="text" class="form-control" v-model="model_label" :class="{ 'is-invalid': errors && errors.indexOf('model_label') > -1 }">
				</div>
			</div>
		</div>
	</form>
</template>

<script type="text/javascript">

	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Accounting from "@/mixins/Accounting.js"
	import Model from "@/mixins/Model.js"

	export default {
		name: "AddModel",
		mixins: [TableAction, Navigation, Accounting, Model],
		props: [
			'modelable_id',
			'modelable_types'
		],
		data () {
			return {
				modelable_type: null,
				model_types: [],
				errors: [],
				modeltype: null,
				model_label: null,
				form_message: '',
				isLoading: false,
				processing: false,
				labelTitle: this.getTrad("model.selectionner"),
				selectedLabel: this.getTrad("global.selected_label"),
			}
		},
		methods: {
			async initModelType() {
				this.$emit('update:ready', false)
				this.isLoading = true
				const model_type = await this.loadModelType([this.modelable_type.id])

				this.model_types = []
				for (let i = 0; i < model_type.length; i++) {
					this.model_types.push({
						id: model_type[i].modeltype_id,
						name: model_type[i].modeltype_label,
					})
				}

				this.isLoading = false
				this.$emit('update:ready', true)
			},

			async addModelType() {
				if(!this.processing) {
					this.processing = true
					this.$emit('update:processing', true)

					this.errors = []

					if(this.modeltype === null) {
						this.errors.push("modeltype_label")
					}

					if(this.model_label === null) {
						this.errors.push("model_label")
					}

					let result = null
					if(this.errors.length == 0) {
						result = await this.createModel(this.modelable_id, this.modeltype.id, this.model_label, this.modelable_type.id)

						if(result) {
							this.$emit('update:processing', false)
							this.processing = false
							this.successToast()
							return true
						}
					}

					this.processing = false
					this.$emit('update:processing', false)
				}

				return false
			}
		},
		computed: {
			modelable_options() {
				let options = []
				this.modelable_types.forEach((modelable_type) => {
					options.push({
						id: modelable_type,
						name: this.$t(`model.modelable_types.${modelable_type}`)
					})
				})
				return options
			}
		},
		watch: {
			modelable_type(_) {
				this.initModelType()
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}
</script>
